<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExperienceNoteSave="onCreate"
  >
    <template v-slot:content="{}">
      <NewExperienceNoteFormVue
        ref="form"
        class="pa-4"
        v-model="form"
        :permissions="value.permissions"
        @action="(action) => action.handler({ from: value })"
      ></NewExperienceNoteFormVue>
    </template>
  </AView>
</template>
  
<script>
import NewExperienceNoteFormVue from "../../../../../../components/wad/organisms/knowledgeBase/experienceNotes/forms/NewExperienceNoteForm.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import { EXPERIENCE_NOTE_BUTTONS } from "../../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    NewExperienceNoteFormVue,
    AView,
  },
  data: () => ({
    form: {},
  }),
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        EXPERIENCE_NOTE_BUTTONS.SAVE,
        this.value.permissions
      );
    },
  },

  methods: {
    onCreate() {
      const valid = this.$refs.form.validate();
      if (valid)
        this.$store.dispatch("ExperienceNotesStore/CreateNewExperienceNote", {
          from: this.value,
          newExperienceNote: this.form,
        });
    },
  },
};
</script>